<template>
    <div>
      <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" @click="showAddBranchModal" icon="el-icon-user"
        round>Add Branch</el-button>
      <article>
        <section>
          <div class="">
            <el-dialog :title="`Add Branch`" :visible.sync="addBranchModal" :destroy-on-close="true"
              :before-close="handleClose" style="text-align: left">
              <div v-loading="submitting">
                <el-form :model="branchForm" :rules="rules" label-position="top" ref="branchForm" class="demo-branchForm2">
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="Branch Name" prop="BranchName">
                        <el-input v-model="branchForm.BranchName" @keyup.enter.native="addBranch('branchForm')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Address" prop="Address">
                        <el-input v-model="branchForm.Address" @keyup.enter.native="addBranch('branchForm')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Email" prop="Email">
                        <el-input v-model="branchForm.Email" @keyup.enter.native="addBranch('branchForm')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Phone" prop="Phone">
                        <el-input v-model="branchForm.Phone" @keyup.enter.native="addBranch('branchForm')"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button type="success" round @click="addBranch('branchForm')">Add Branch
                      </el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co"
                        style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
       addBranchModal: false,
        editBranchModal: false,
        value: "",
        loadingError: false,
        submitting: false,
        branchForm: {
            BranchName: "",
            Address: "",
            Email: "",
            Phone: "",
        },
  
        rules: {
          BranchName: [ 
            { required: true, message: "Branch Name is required", trigger: "blur", },
          ],
          Address: [
            { required: true, message: "Address is required", trigger: "blur" },
          ],
          Email: [ 
            { required: true, message: "Email is required", trigger: "blur", },
          ],
          Phone: [
            { required: true, message: "Phone is required", trigger: "blur" },
          ]
        },
      };
    },
  
    methods: {
      handleClose(done) {
        done();
      },
  
      showAddBranchModal() {
        this.addBranchModal = true;
        this.branchForm.BranchName = "";
        this.branchForm.Address = "";
        this.branchForm.Email = "";
        this.branchForm.Phone = "";
      },
  
      async addBranch(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.post(`branches`, {
                BranchName: this.branchForm.BranchName,
                Address: this.branchForm.Address,
                Email: this.branchForm.Email,
                Phone: this.branchForm.Phone,
                addedBy: this.$store.state.userId,
                company_id: this.$store.state.company.company_id,
              });
              if (
                response.data.success &&
                response.data.message == "Branch created successfully"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Branch added successfully",
                  type: "success",
                });
                this.addBranchModal = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to add branch",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style></style>