import { render, staticRenderFns } from "./edit-branch-dialog.vue?vue&type=template&id=05289a8b&scoped=true"
import script from "./edit-branch-dialog.vue?vue&type=script&lang=js"
export * from "./edit-branch-dialog.vue?vue&type=script&lang=js"
import style0 from "./edit-branch-dialog.vue?vue&type=style&index=0&id=05289a8b&prod&scoped=true&lang=css"
import style1 from "./edit-branch-dialog.vue?vue&type=style&index=1&id=05289a8b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05289a8b",
  null
  
)

export default component.exports